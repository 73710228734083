export default {
  "link-about-us": "О КОМПАНИИ",
  "link-travel": "ПАССАЖИРАМ",
  "link-partner": "ПИЛОТАМ",
  "link-contact": "КОНТАКТЫ",
  "link-login": "Авторизоваться",
  "link-faq": "ВОПРОСЫ",
  "follow-us": "Следуйте за нами",
  "home-page-title": "Бронирование вертолётов - просто и быстро, как никогда",
  "home-page-subtitle-1": "Закажите полет",
  "home-page-subtitle-2": "Становитесь партнером",
  "home-text-user": "Бронируйте вертолёт для полетов по бизнесу и на отдыхе",
  "home-text-partner": "Регистрируйте вертолёты для эффективной эксплуатации",
  "view-more": "Узнать больше",
  "search-helicopters": "Поисковые вертолеты",
  "register-as-provider": "Зарегистрируйтесь как провайдер",
  "about-us-page-title":
    "Добро пожаловать на уникальную платформу для бронирования вертолётов FindHeli",
  "about-us-mid-title": "Что такое FindHeli?",
  "about-us-mid-subtitle-1": "Удобное решение",
  "about-us-mid-text-1":
    "FindHeli быстро находит оптимальное решение для деловых людей и вдохновленных путешественников.",
  "about-us-mid-subtitle-2": "Надежное Приложение",
  "about-us-mid-text-2":
    "Быстрое и удобное интернет-приложение. Не требует регистрации, загрузки или установки на смартфон.",
  "about-us-mid-subtitle-3": "Единая Платформа",
  "about-us-mid-text-3":
    "Объединяет пользователей и самые надежные вертолётные компании по всему миру.",
  "who-is-it-for": "Для кого это?",
  "who-is-it-for-text":
    "FindHeli - решение для деловых людей и часто путешествующих пассажиров.",
  "how-does-it-work": "Как это работает?",
  "how-does-it-work-text":
    "Определив геоположение, или желаемую точку отправления, FindHeli обнаруживает все имеющиеся в наличии вертолёты",
  "where-does-it-work": "Где используется?",
  "where-does-it-work-text":
    "Платформа FindHeli активно развивается и в скором времени будет доступна в следующих странах:",
  usa: "США",
  "united-kingdom": "Великобритания",
  europe: "Европа",
  switzerland: "Швейцария",
  russia: "Россия",
  "become-a-findheli-partner": "Становитесь Партнером FindHeli",
  "partner-account-initiation-process": "Процесс регистрации Партнеров",
  "request-an-account": "Оставьте заявку",
  "request-an-account-text-1":
    "Зайдите на www.findheli.com/partner и оставьте заявку на открытие счета",
  "request-an-account-text-2": "Просмотрите и примите Условия использования",
  "sign-into-admin-panel": "Панель управления",
  "sign-into-admin-panel-text-1":
    "Дождитесь подтверждения об открытии счета по электронной почте и активируйте его.",
  "sign-into-admin-panel-text-2":
    "Войдите с информацией, отправленной по электронной почте",
  "start-using-findheli": "Используйте платформу FindHeli",
  "start-using-findheli-text":
    "Регистрируйте свои вертолёты, площадки и специальные предложения.",
  "become-a-partner": "Стать Партнером",
  "submit-a-request": "Оставить Заявку",
  "first-and-last-name": "Укажите Ваше имя",
  "company-name": "Название компании",
  country: "Страна",
  website: "Интернет-сайт",
  email: "Адрес электронной почты",
  "your-message": "Сообщение",
  "send-partnership-request": "Отправить запрос",
  "frequently-asked-questions": "Часто задаваемые вопросы",
  "about-findheli-booking-platform": "Часто задаваемые вопросы",
  "what-is-findheli-and-who-is-it-for": "WЧто такое FindHeli и для кого это?",
  "what-is-findheli-and-who-is-it-for-text":
    "FindHeli - универсальная система для онлайн-бронирования вертолётов. FindHeli делает этот процесс  простым и быстрым. Приложение создано для удобства деловых людей и часто путешествующих пассажиров.",
  "how-does-it-work-text-2":
    "Пользователь FindHeli выбирает место вылета и направлекние полета, дату, время и количество пассажиров. Согласно запросу, FindHeli оперативно предложит все имеющиеся в наличии вертолёты и расчетные цены полета.",
  "where-it-currently-operates": "Где используют FindHeli?",
  "where-it-currently-operates-text":
    "Со дня основания в Англии, FindHeli активно развивается во многих странах и регионах мира от Азии и Дальнего Востока до Северной и Южной Америки.  Партнеры компании уже осуществляют пассажирские перевозки в США и Великобританиии, Швейцарии и Европе, а также в скором времени и в России.",
  "how-the-flight-price-is-calculated": "Как формируется цена полета?",
  "how-the-flight-price-is-calculated-text":
    "Для расчета не нужно глубоких познаний в высшей математике. FindHeli использует ряд стандартных параметров, таких как: дальность полета, расход топлива, погодные условия и обслуживание вертолёта.",
  "how-long-it-takes-to-confirm-the-booking":
    "Как долго занимает бронирование?",
  "how-long-it-takes-to-confirm-the-booking-text":
    "FindHeli незамедлительно коммуницирует детали заявки на бронирование Оператору.  В зависимости от географии и сложности полета, подтверждение о наличии вертолёта может занимать до 24 часов. С мометна получения подтверждения, пользователю предоставляется возможность гарантировать свой полет, используя кредитную карту.",
  "what-else-do-i-need-to-know": "Что ещё необходимо знать?",
  "what-else-do-i-need-to-know-text":
    "Идентично с регулярными рейсами авиакомпаний вам потребуется удостоверение личности с фотографией. Прибывать в местоположение вертодрома следует заранее, за 30-40 минут для прохождения регистрационных действий и формальностей.",
  "find-a-helicopter-anytime-and-anywhere": "По воздуху, всегда и везде.",
  "open-findheli-app": "Откройте приложение Findheli",
  "how-does-findheli-work": "Как работает FindHeli?",
  "input-your-trip-details": "Укажите данные",
  "input-your-trip-details-text":
    "Укажите место вылета, желаемую дату и время вашего полета.",
  "select-an-aircraft": "Выберете вертолет",
  "select-an-aircraft-text":
    "Выберете  подходящий вам вертолет и запросите подтверждение оператора.",
  "review-flight-proposal": "Подтвердите условия",
  "review-flight-proposal-text":
    "Внимательно изучите все условия вашего полета",
  "confirm-and-book-flight": "Забронируйте полет",
  "confirm-and-book-flight-text":
    "Подтвердите бронирование с помощью авторизации вашей кредитной карты.",
  "what-findheli-offers": "Что предлагает FindHeli?",
  "destination-flights": "Эксклюзивные полеты",
  "destination-flights-text":
    "Выбирайте направление и удобный вертолет для вашего полета.",
  "scenic-flights": "Специальные предложения",
  "scenic-flights-text":
    "Испытайте незабываемые впечатления от полетов на вертлете.",
  "heli-ski-drop-offs": "Трансферы в горы",
  "heli-ski-drop-offs-text":
    "Требуется вертолет для трансфера на горнолыжный курорт? Бронируйте используя приложение FindHeli.",
  "why-to-use-findheli": "Выбирайте FindHeli!",
  "global-and-international": "Международная платформа.",
  "global-and-international-text":
    "Приложение доступно во многих странах мира на нескольких языках, включая русский.",
  "instant-price-estimate": "Мгновенный расчет стоимости полета.",
  "instant-price-estimate-text":
    "Выберете дату и направление и узнайте цену вашего полета.",
  "quick-confirmation": "Быстрое подтверждение.",
  "quick-confirmation-text":
    "Мы стремимся максимально быстро подтвердить бронирование.",
  "secured-data-protection": "Безопасно.",
  "secured-data-protection-text":
    "Данные наших пользователей надежно защищены.",
  "an-elevated-way-of-travel": "Возвышенный способ путешествовать",
  "findHeli-is-easy-to-use": "FindHeli прост в использовании",
  "search-for-helicopters-text":
    "Находите и бронируйте вертолеты прямо в приложении.",
  "extend-your-operations-with-findheli":
    "Добро пожаловать на уникальную платформу для бронирования вертолётов FindHeli",
  "why-register-in-findheli-booking-platform":
    "Зачем регистрироваться на платформе бронирования FindHeli?",
  "global-network": "Весь Мир - одно решение",
  "global-network-text":
    "Получите доступ к международной сети премиальных пользователей.",
  "efficient-fleet-load": "Удобное решение",
  "efficient-fleet-load-text":
    "Получайте дополнительные доходы от продаж запасных мест на перелетных и обратных рейсах",
  "cost-saving-marketing": "Эффективный маркетинг",
  "cost-saving-marketing-text": "Сократите расходы на маркетинг",
  "cash-free-payments": "Безналичные расчеты",
  "cash-free-payments-text": "Используйте безналичные расчеты с пассажирами.",
  "easy-to-use-control-panel": "Личный кабинет оператора",
  "easy-to-use-control-panel-text":
    "Управляйте процессом через личный кабинет оператора. Регистрируйте ващи вертолеты и вертодромы.",
  "crew-and-fleet-management": "Управление парком",
  "crew-and-fleet-management-text-1":
    "Отслеживайте загрузку парка в разделе Мои Вертолеты.",
  "crew-and-fleet-management-text-2":
    "Назначайте экипажи для выполнения рейсов забронированных пользователями.",
  "special-offers-extra-revenues": "Специальные предложения",
  "special-offers-extra-revenues-text-1":
    "Размещайте специальные предложения и эксклюзивные полеты.",
  "special-offers-extra-revenues-text-2":
    "Зарабатывайте на свободных рейсах в обратном направлении, размещая информацию о них в приложении.",
  "contact-us": "Связаться с нами",
  "contact-us-text":
    "Возникли проблемы с забронированными рейсами? Не можете найти ответы на свои вопросы в нашем FAQ?",
  "get-in-touch-with-our-support-team": "Свяжитесь с нашей службой поддержки",
  "send-us-a-message": "Отправьте нам сообщение",
  "first-name": "Имя",
  "last-name": "Фамилия",
  phone: "Телефон",
  "how-can-we-help-you": "Как мы можем тебе помочь?",
  send: "послать"
};
