import $ from "jquery";
import axios from "axios";

import Toast from "./toast";
import { API_URL } from "../../config/settings";

const SENT_MESSAGE = "Your contact form was sent";
const EMPTY_MESSAGE = "Please fill all the inputs";
const SERVER_ERROR = "Server error";

class Contact {
  constructor(buttonClass) {
    this.$button = $(buttonClass);
    this.$firstNameField = $(".contact-first-name");
    this.$lastNameField = $(".contact-last-name");
    this.$emailField = $(".contact-email");
    this.$phoneField = $(".contact-phone");
    this.$messageField = $(".contact-message");
    this.buttonText = this.$button.text();
    this.handleRequest = this.handleRequest.bind(this);
    this.handleError = this.handleError.bind(this);
    this.toast = new Toast();

    this.addEventListeners();
  }

  addEventListeners() {
    this.$button.on("click", e => this.handleSubmit(e));
  }

  handleSubmit(event) {
    event.preventDefault();

    const payload = {
      first_name: this.$firstNameField.val(),
      last_name: this.$lastNameField.val(),
      email: this.$emailField.val(),
      phone: this.$phoneField.val(),
      message: this.$messageField.val()
    };

    if (
      !payload.first_name.length ||
      !payload.last_name.length ||
      !payload.email.length ||
      !payload.phone.length ||
      !payload.message.length
    ) {
      this.toast.setMessage(EMPTY_MESSAGE);
      this.toast.show();
      return;
    }

    this.renderLoader();
    axios
      .post(`${API_URL}/contact/`, payload)
      .then(this.handleRequest)
      .catch(this.handleError);
  }

  handleRequest() {
    this.$button.text(this.buttonText);
    this.toast.setMessage(SENT_MESSAGE);
    this.toast.show();
    this.clearFields();
  }

  handleError() {
    this.$button.text(this.buttonText);
    this.toast.setMessage(SERVER_ERROR);
    this.toast.show();
  }

  clearFields() {
    this.$firstNameField.val("");
    this.$lastNameField.val("");
    this.$emailField.val("");
    this.$phoneField.val("");
    this.$messageField.val("");
  }

  renderLoader() {
    const loader = `
      <div class="loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    `;

    this.$button.html(loader);
  }
}

export default Contact;
