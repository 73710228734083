const mobileButton = document.getElementById("mobile-button");
const mobileNav = document.getElementById("mobile-nav");

const buttonClass = mobileButton.classList;
const navClass = mobileNav.classList;

const body = document.getElementsByTagName("BODY")[0];

const mobileNavlinks = Array.from(document.querySelectorAll("#mobile-nav a"));

function toggleMobileNav() {
  if (buttonClass.contains("active") && navClass.contains("active")) {
    buttonClass.remove("active");
    navClass.remove("active");
    body.style.overflow = "scroll";
  } else {
    buttonClass.add("active");
    navClass.add("active");
    body.style.overflow = "hidden";
  }
}

mobileButton.addEventListener("click", () => {
  toggleMobileNav();
});
