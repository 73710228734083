export default {
  "link-about-us": "QUI SOMMES-NOUS",
  "link-travel": "VOTRE VOYAGE",
  "link-partner": "DEVENIR PARTENAIRE",
  "link-contact": "CONTACTS",
  "link-login": "S'identifier",
  "link-faq": "FAQ",
  "follow-us": "Suivez nous",
  "home-page-title": "Réservation d'un hélico plus facile que jamais",
  "home-page-subtitle-1": "Réservez votre vol",
  "home-page-subtitle-2": "Devenez notre partenaire",
  "home-text-user": "Réservez un hélico pour le business ou les vacances",
  "home-text-partner": "Enregistrez des hélicoptères pour les rentabiliser",
  "view-more": "Pour en savoir plus",
  "search-helicopters": "Hélicoptères de recherche",
  "register-as-provider": "S'inscrire en tant que fournisseur",
  "about-us-page-title":
    "Bienvenue sur FindHeli - Réservation d'hélicoptère en ligne",
  "about-us-mid-title": "C'est quoi FindHeli?",
  "about-us-mid-subtitle-1": "Solution globale",
  "about-us-mid-text-1":
    "FindHeli connecte des clients du monde entier avec les opérateurs d'hélicoptères.",
  "about-us-mid-subtitle-2": "App Web fiable",
  "about-us-mid-text-2":
    "Pas d'installation, pas de téléchargement sur le smartphone.",
  "about-us-mid-subtitle-3": "Plateforme de réservation",
  "about-us-mid-text-3":
    "FindHeli réunit des clients et des opérateurs confirmés à travers le monde",
  "who-is-it-for": "Pour qui travaillons-nous?",
  "who-is-it-for-text":
    "FindHeli  App est destiné aux voyageurs fréquants qui cherchent à optimiser leurs déplacements.",
  "how-does-it-work": "Comment ça marche?",
  "how-does-it-work-text":
    "FindHéli localise tous les hélicoptères disponibles aux environs du point de départ.",
  "where-does-it-work": "Où peut-on y avoir accès?",
  "where-does-it-work-text":
    "FindHeli est en train de développer ses services dans les pays suivants:",
  usa: "Etats Unis",
  "united-kingdom": "Royaume Uni",
  europe: "Europe",
  switzerland: "Suisse",
  russia: "Russie",
  "become-a-findheli-partner": "Devenez notre partenaire",
  "partner-account-initiation-process": "Comment devenir partenaire",
  "request-an-account": "Laissez votre demande",
  "request-an-account-text-1":
    "Sur le site www.findheli.com/partner laissez votre demande",
  "request-an-account-text-2": "Examiner et accepter les conditions générales",
  "sign-into-admin-panel": "Panel de gestion",
  "sign-into-admin-panel-text-1":
    "Après avoir reçu la confirmation par courriel, validez votre inscription.",
  "sign-into-admin-panel-text-2":
    "Connectez-vous avec les informations envoyées par e-mail",
  "start-using-findheli": "Utilisez FinfHeli",
  "start-using-findheli-text":
    "Enregistrez vos offres d'hélicos et d'héliports",
  "become-a-partner": "Devenir notre partenaire",
  "submit-a-request": "Laisser votre demande",
  "first-and-last-name": "Votre nom et prénom",
  "company-name": "Nom de votre Société",
  country: "Pays",
  website: "Votre site WEB",
  email: "Votre courriel",
  "your-message": "Votre message",
  "send-partnership-request": "Envoyer votre message",
  "frequently-asked-questions": "Foire aux questions",
  "about-findheli-booking-platform": "Foire aux questions",
  "what-is-findheli-and-who-is-it-for": "Qui cela concerne-t-il?",
  "what-is-findheli-and-who-is-it-for-text":
    "FindHeli est une plateforme en ligne qui permet de réserver un hélicoptère en quelques clics. Notre application est destinée aux voyageurs fréquants",
  "how-does-it-work-text-2":
    "Le client de FindHeli choisit le lieu de départ, la destination, la date et l'heure du vol, le nombre de passagers. Selon la demande FindHeli App proposera tous les hélicos disponibles et le devis.",
  "where-it-currently-operates": "Où sommes-nous disponibles?",
  "where-it-currently-operates-text":
    "Créé en Grande Bretagne, FindHeli étant en pleine expension à travers le monde, assure ses services aux Etats Unis, en France, en Suisse, en Italie et en Russie",
  "how-the-flight-price-is-calculated": "Comment le prix est-il fixé?",
  "how-the-flight-price-is-calculated-text":
    "Pas besoin de connaissances approfondies en maths. FindHeli tient compte des paramètres comme la distance, le modèle d'hélico, les frais de l'aéroport, la météo et les taxes",
  "how-long-it-takes-to-confirm-the-booking": "Combien de temps pour réserver?",
  "how-long-it-takes-to-confirm-the-booking-text":
    "FindHeli communique immédiatement les détails de votre réservation à l'Opérateur. La confirmation vous arrivera en 24 heures. Une fois la confirmation reçue, vous pouvez valider votre commande avec votre carte bancaire.",
  "what-else-do-i-need-to-know": "Que faut-il savoir de plus?",
  "what-else-do-i-need-to-know-text":
    "Tout comme pour un voyage en avion, vous devez présenter votre carte d'identité avec photo. Il faut arriver à l'héliport 30-40 min avant l'embarquement.",
  "find-a-helicopter-anytime-and-anywhere":
    "Trouvez votre hélicoptère quand vous voulez et où voulez",
  "open-findheli-app": "Ouvrez l'application Findheli",
  "how-does-findheli-work": "Comment ça marche?",
  "input-your-trip-details": "Insérez vos donnés",
  "input-your-trip-details-text":
    "Choisissez le point de départ, la destination, la date et l'heure de votre vol.",
  "select-an-aircraft": "Choisissez votre hélicoptère",
  "select-an-aircraft-text":
    "Choisissez un hélico le plus proche et disponible",
  "review-flight-proposal": "Validez votre demande",
  "review-flight-proposal-text": "Validez l'offre envoyée par FinfHeli",
  "confirm-and-book-flight": "Confirmez votre réservation",
  "confirm-and-book-flight-text":
    "Une fois la confirmation reçue, vous pouvez valider votre commande avec votre carte bancaire.",
  "what-findheli-offers": "FindHeli que propose-t-il?",
  "destination-flights": "Vols de destination",
  "destination-flights-text":
    "Choisissez votre destination et l'hélico qui vous convient le mieux.",
  "scenic-flights": "Vols panoramiques",
  "scenic-flights-text":
    "Profitez de vues imprenables dans les coins du monde les plus pitoresques",
  "heli-ski-drop-offs": "Dépose d'héliski",
  "heli-ski-drop-offs-text":
    "Vous cherchez un hélico pour aller en montagnes? FindHeli peut vous aider.",
  "why-to-use-findheli": "Choisissez FindHeli!",
  "global-and-international": "Disponible dans le monde entier",
  "global-and-international-text": "Disponibles en plusieurs langues",
  "instant-price-estimate": "Prix calculé immédiatement",
  "instant-price-estimate-text":
    "Choisissez la date et la destination pour savoir le prix de votre vol",
  "quick-confirmation": "Confirmation rapide",
  "quick-confirmation-text":
    "Nous ferons tout pour confirmer votre vol en 24 heures",
  "secured-data-protection": "Sécurité",
  "secured-data-protection-text": "Protection des données des clients",
  "an-elevated-way-of-travel": "Un moyen de transport surélevé",
  "findHeli-is-easy-to-use": "FindHeli est facile à utiliser",
  "search-for-helicopters-text":
    "Rechercher un hélico et le réserver en ligne sur la plateforme FindHeli",
  "extend-your-operations-with-findheli":
    "Bienvenue sur FindHeli - réservation de l'hélicoptère en ligne",
  "why-register-in-findheli-booking-platform":
    "Pourquoi s'inscrire à FindHeli?",
  "global-network": "Solution globale",
  "global-network-text":
    "Accès au réseau de la clientèle premium internationale",
  "efficient-fleet-load": "Solution rentable",
  "efficient-fleet-load-text":
    "Gain supplémentaire dû à la vente des sièges disponibles",
  "cost-saving-marketing": "Marketing efficace",
  "cost-saving-marketing-text":
    "Economie du budget-marketing par publicité ciblée",
  "cash-free-payments": "Règlements scripturaux",
  "cash-free-payments-text": "Système des paiements fiable sans espèces",
  "easy-to-use-control-panel": "Panel de gestion fonctionnel",
  "easy-to-use-control-panel-text":
    "Gerez votre business sur le panel de contrôle de FindHeli. Enrégistrez vos moyens aériens, héliports et vos disponibilités",
  "crew-and-fleet-management": "Gestion du personnel de bord & hélicos",
  "crew-and-fleet-management-text-1":
    "Gérez l'exploitation de vos moyens aériens et vos diponibilités avec le panel de contrôle",
  "crew-and-fleet-management-text-2":
    "Désignez vos pilotes pour les vols réservés par les clients de FindHeli",
  "special-offers-extra-revenues": "Offres spéciales",
  "special-offers-extra-revenues-text-1":
    "Promotion des offres spéciales et exclusives pour les clients de FingHeli.",
  "special-offers-extra-revenues-text-2":
    "Gain supplémentaire grâce aux avertissements de notre App Web avancée",
  "contact-us": "Contactez nous",
  "contact-us-text":
    "Vous avez des problèmes avec vos vols réservés? Impossible de trouver des réponses à vos questions dans notre FAQ?",
  "get-in-touch-with-our-support-team": "Contactez notre équipe de support",
  "send-us-a-message": "Envoie-nous un message",
  "first-name": "Prénom",
  "last-name": "Nom de famille",
  phone: "Téléphone",
  "how-can-we-help-you": "Comment pouvons-nous t'aider?",
  send: "Envoyer"
};
