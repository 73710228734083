import $ from "jquery";
import { COUNTRIES } from "../../config/settings";
import Toast from "./toast";

if (!Array.from) Array.from = require("array-from");
const inputs = Array.from(document.querySelectorAll(".input"));

$(".input-select").append(
  $("<option>", {
    value: "",
    text: "Country",
    "data-lang": "country"
  })
);

COUNTRIES.forEach(country => {
  $(".input-select").append(
    $("<option>", { value: country.name, text: country.name })
  );
});

inputs.forEach(el => {
  el.addEventListener("focus", ({ target }) => {
    let targetClass = target.parentElement.classList;
    targetClass.add("active");
  });
  el.addEventListener("blur", ({ target }) => {
    let targetClass = target.parentElement.classList;
    if (!target.value) {
      targetClass.remove("active");
    }
  });
});

const REGISTER_PROVIDER_URL =
  "https://api.findheli.com/api/v1/register/provider/sales_force/";

const submitPartnerForm = event => {
  event.preventDefault();

  // Loading message
  $("[js-partner-form]").off("submit");
  $("[js-partner-button]")
    .html("Sending...")
    .attr("disabled", "disabled");
  
  console.log('aoooooo', $("[js-partner-button]").val())

  const name = $("[js-partner-name]").val();
  const company = $("[js-partner-company]").val();
  const email = $("[js-partner-email]").val();
  const country = $("[js-partner-country]").val();
  const website = $("[js-partner-website]").val();
  const message = $("[js-partner-message]").val();

  if (name && company && email && message) {
    $.post(
      REGISTER_PROVIDER_URL,
      {
        full_name: name,
        company_name: company,
        country,
        website,
        email,
        message
      },
      function(data) {
        // Clean input states
        $("[js-partner-name]").val("");
        $("[js-partner-company]").val("");
        $("[js-partner-email]").val("");
        $("[js-partner-country]").val("");
        $("[js-partner-website]").val("");
        $("[js-partner-message]").val("");

        // Reset button to final state
        $("[js-partner-button]").val("Contact successfully sent, thanks!");

        // Reset form after 4 seconds
        var timer = setTimeout(function() {
          // Reset button and form to normal state
          $("[js-partner-button]")
            .html("Send Partnership Request")
            .attr("disabled", false);
          $("[js-partner-form]").on("submit", submitPartnerForm);
        }, 4000);
      },
    ).fail(function(){
      this.toast = new Toast();
      this.toast.setMessage('An error occurred, please try again later.');
      this.toast.show();
      $("[js-partner-button]")
        .html("Send Partnership Request")
        .attr("disabled", false);
      $("[js-partner-form]").on("submit", submitPartnerForm);
    });
  } else {
    // Reset button and form to normal state
    $("[js-partner-button]")
      .html("Send Partnership Request")
      .attr("disabled", false);
    $("[js-partner-form]").on("submit", submitPartnerForm);
  }

  return false;
};

$("[js-partner-form]").on("submit", submitPartnerForm);
