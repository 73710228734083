import $ from "jquery";
import enStrings from "../../languages/en";
import frStrings from "../../languages/fr";
import ruStrings from "../../languages/ru";
import ptStrings from "../../languages/pt";

const DEFAULT_LANGUAGES = {
  EN: {
    label: "EN",
    flagSrc: "/img/img_uk_flag@3x.svg",
    texts: enStrings
  },
  FR: {
    label: "FR",
    flagSrc: "/img/img_france_flag.png",
    texts: frStrings
  },
  RU: {
    label: "RU",
    flagSrc: "/img/Flag_of_Russia.jpg",
    texts: ruStrings
  },
  PT: {
    label: "PT",
    flagSrc: "/img/img_br_flag.svg",
    texts: ptStrings
  }
};

class Translator {
  constructor({
    triggerSelector,
    dropdownSelector,
    containerSelector,
    dropdownOption
  }) {
    this.language = "EN";
    this.trigger = triggerSelector;
    this.dropdown = dropdownSelector;
    this.$container = $(containerSelector);
    this.dropdownOption = dropdownOption;

    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.handleLanguageSwitch = this.handleLanguageSwitch.bind(this);

    this.initialize();
    this.addEventListeners();
  }

  initialize() {
    const language = localStorage.getItem("language");

    this.setLanguage(language || this.language);
    this.render();
    this.translate();
  }

  setLanguage(language) {
    this.language = language;
  }

  storeLanguage(language) {
    localStorage.setItem("language", language);
  }

  addEventListeners() {
    $(document).on("click", this.trigger, this.handleToggleClick);
    $(document).on("click", this.dropdownOption, this.handleLanguageSwitch);
  }

  handleToggleClick() {
    $(this.dropdown).toggleClass("active");
  }

  handleLanguageSwitch(event) {
    const language = $(event.currentTarget)
      .find("span")
      .text();

    this.setLanguage(language);
    this.storeLanguage(language);
    this.render();
    this.translate();
  }

  translate() {
    const languageStrings = DEFAULT_LANGUAGES[this.language].texts;

    Object.keys(languageStrings).forEach(string => {
      $(`[data-lang="${string}"]`).html(
        DEFAULT_LANGUAGES[this.language].texts[string]
      );
    });
  }

  renderButton(lang) {
    return `
      <button class="dropdown-menu-button">
        <span class="dropdown-menu-button-text">${
          DEFAULT_LANGUAGES[lang].label
        }</span>
        <img
          class="dropdown-menu-button-flag"
          src="${DEFAULT_LANGUAGES[lang].flagSrc}"
        />
      </button>
    `;
  }

  renderDropdown(currentLanguage, othersLanguages) {
    return `
      <button class="dropbtn dropdown-trigger">
        <div class="lang-container">
          <div class="lang">${DEFAULT_LANGUAGES[currentLanguage].label}</div>
          <div><img class="lang-flag" src="${
            DEFAULT_LANGUAGES[currentLanguage].flagSrc
          }" /></div>
          <span class="dropbtn-arrow"></span>
        </div>
      </button>
      <div class="dropdown-menu">
        ${othersLanguages.map(lang => this.renderButton(lang)).join("")}
      </div>
    `;
  }

  render() {
    const othersLanguages = Object.keys(DEFAULT_LANGUAGES).filter(
      lang => lang !== this.language
    );
    const output = this.renderDropdown(this.language, othersLanguages);
    this.$container.html(output);
  }
}

export default Translator;
