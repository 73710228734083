export default {
  "link-about-us": "About us",
  "link-travel": "Travel",
  "link-partner": "Become a partner",
  "link-contact": "Contact",
  "link-login": "Login",
  "link-faq": "FAQ",
  "follow-us": "Follow us",
  "home-page-title": "Booking a helicopter is now easier than ever before",
  "home-page-subtitle-1": "Book a Flight",
  "home-page-subtitle-2": "Become a Partner",
  "home-text-user":
    "Request a helicopter that suits you the best. Anytime and anywhere.",
  "home-text-partner":
    "Register helicopters to fly FindHeli customers from all over the world.",
  "view-more": "View More",
  "search-helicopters": "Search Helicopters",
  "register-as-provider": "Register as provider",
  "about-us-page-title":
    "Welcome to FindHeli - Global Online Helicopter Booking Platform",
  "about-us-mid-title": "What is FindHeli?",
  "about-us-mid-subtitle-1": "A Global Solution",
  "about-us-mid-text-1":
    "FindHeli connects customers travelling across the globe with most relevant helicopter operators in just few clicks.",
  "about-us-subtitle-2": "Progressive Web App",
  "about-us-mid-text-2":
    "No need to install on smartphone. Our online app can be used on desktops and mobile phones and works offline.",
  "about-us-mid-subtitle-3": "Booking Platform",
  "about-us-mid-text-3":
    "FindHeli Partners receive and review requests online and operate flight for customers coming from all over the world.",
  "who-is-it-for": "Who is it for?",
  "who-is-it-for-text":
    "FindHeli App designed for frequently traveling customers willing to find most efficient way and pleasant of travel using helicopters.",
  "how-does-it-work": "How does it work?",
  "how-does-it-work-text":
    "FindHeli App designed for frequently traveling customers willing to find most efficient way and pleasant of travel using helicopters.",
  "where-does-it-work": "Where does it work?",
  "where-does-it-work-text":
    "FindHeli global online helicopter-booking platform currently operates in the following countries:",
  usa: "USA",
  "united-kingdom": "United Kingdom",
  europe: "Europe",
  switzerland: "Switzerland",
  russia: "Russia",
  "become-a-findheli-partner": "Become a FindHeli Partner",
  "partner-account-initiation-process": "Partner account initiation process",
  "request-an-account": "Request an Account",
  "request-an-account-text-1":
    "Visit www.findheli.com/partner to request an account setup.",
  "request-an-account-text-2": "Review and accept Terms and Conditions",
  "sign-into-admin-panel": "Sign into Admin Panel",
  "sign-into-admin-panel-text-1":
    "Wait for FindHeli to review and validate your account.",
  "sign-into-admin-panel-text-2": "Sign in with information sent by e-mail",
  "start-using-findheli": "Start using FindHeli",
  "start-using-findheli-text":
    "Register your heliports, fleet and special offers.",
  "become-a-partner": "Become a partner",
  "submit-a-request": "Submit a Request",
  "first-and-last-name": "First and Last Name",
  "company-name": "Company Name",
  country: "Country",
  website: "Website",
  email: "E-mail",
  "your-message": "Your Message",
  "send-partnership-request": "Send Partnership Request",
  "frequently-asked-questions": "Frequently Asked Questions",
  "about-findheli-booking-platform": "About Findheli Booking Platform",
  "what-is-findheli-and-who-is-it-for": "What is FindHeli and Who is it for?",
  "what-is-findheli-and-who-is-it-for-text":
    "FindHeli is a universal online helicopter-booking platform. It makes helicopter booking process simple, quick and easy. Our online App is designed for frequently traveling customers willing to use helicopter",
  "how-does-it-work-text-2":
    "FindHeli user simply chooses the pick-up and drop-off locations, date and time for the flight, number of passengers and submits a helicopter search. Following user request FindHeli App matches a traveler with the most appropriate helicopters available nearby.",
  "where-it-currently-operates": "Where it currently operates?",
  "where-it-currently-operates-text":
    "Initially originated in England, FindHeli operates globally in various locations including United States, France, Switzerland, Italy and Russia with many other countries coming up. Since launch, platform rapidly extends its coverage from far east to the west.",
  "how-the-flight-price-is-calculated": "How the flight price is calculated",
  "how-the-flight-price-is-calculated-text":
    "There is no complicated calculation math behind. FindHeli simply uses a variety of factors that typically affect a fare, including helicopter type and model, airport traffic, take-off and landing tolls, as well as weather conditions and taxes",
  "how-long-it-takes-to-confirm-the-booking":
    "How long it takes to confirm the booking?",
  "how-long-it-takes-to-confirm-the-booking-text":
    "FindHeli communicates your booking details to the relevant Operator. It might take up to 24 hours to confirm helicopter availability. Once your reservation is confirmed, FindHeli will send you a confirmation email with all your flight details in the name and on behalf of the Operator. Then you have guarantee your flight with your credit card.",
  "what-else-do-i-need-to-know": "What else do I need to know?",
  "what-else-do-i-need-to-know-text":
    "Identically to regular airlines, you will be asked for a proof of identity before boarding the flight. You will need to arrive to the heliport 20-30 min before the flight for the boarding formalities. All FindHeli operators are duly certified and licensed.",
  "find-a-helicopter-anytime-and-anywhere":
    "Find a Helicopter anytime and anywhere",
  "open-findheli-app": "Open Findheli App",
  "how-does-findheli-work": "How does Findheli work?",
  "input-your-trip-details": "Input your trip details",
  "input-your-trip-details-text":
    "Choose take-off location, destination, date & time of your flight to select one that suits you best.",
  "select-an-aircraft": "Select an Aircraft",
  "select-an-aircraft-text":
    "Select from multiple helicopters nearby and request for availability.",
  "review-flight-proposal": "Review Flight Proposal",
  "review-flight-proposal-text":
    "Review the flight details in the proposal sent by FindHeli App and accept it.",
  "confirm-and-book-flight": "Confirm and Book Flight",
  "confirm-and-book-flight-text":
    "After accepting proposal, confirm your flight reservation with secured credit card pre-authorization.",
  "what-findheli-offers": "What FindHeli Offers?",
  "destination-flights": "Destination Flights",
  "destination-flights-text":
    "Set your take off location and destination and find the best helicopter available for your trip.",
  "scenic-flights": "Scenic Flights",
  "scenic-flights-text":
    "Special offers for stunning aerial views from cities and landscapes available worldwide.",
  "heli-ski-drop-offs": "Heli Ski Drop Offs",
  "heli-ski-drop-offs-text":
    "Need a helicopter for reaching the mountains? Easily reserve one on FindHeli platform.",
  "why-to-use-findheli": "Why to use FindHeli?",
  "global-and-international": "Global & International",
  "global-and-international-text":
    "Available in multiple languages in various locations around the world.",
  "instant-price-estimate": "Instant Price Estimate",
  "instant-price-estimate-text":
    "Choose your take off location, destination, number of passengers, date & time.",
  "quick-confirmation": "Quick Confirmation",
  "instant-price-estimate-text":
    "Choose your take off location, destination, number of passengers, date & time.",
  "quick-confirmation": "Quick Confirmation",
  "quick-confirmation-text":
    "We encourage helicopter operators to confirm availability of the aircraft within 24 hours.",
  "secured-data-protection": "Secured Data Protection",
  "secured-data-protection-text":
    "FindHeli users have their information securely kept in our database.",
  "an-elevated-way-of-travel": "An elevated way of travel",
  "findHeli-is-easy-to-use": "FindHeli is easy to use",
  "search-for-helicopters-text":
    "Search for helicopters and book a flight directly in FindHeli Online Booking Platform.",
  "extend-your-operations-with-findheli": "Extend Your Operation with FindHeli",
  "why-register-in-findheli-booking-platform":
    "Why register in FindHeli Booking Platform?",
  "global-network": "Global Network",
  "global-network-text":
    "Access FindHeli's multi-national premium customer global network",
  "efficient-fleet-load": "Efficient Fleet Load",
  "efficient-fleet-load-text":
    "Earn extra revenues from spare seats sales available on carry & return flights",
  "cost-saving-marketing": "Cost-Saving Marketing",
  "cost-saving-marketing-text":
    "Reduce marketing cost on targeted campaigns using FindHeli platform",
  "cash-free-payments": "Cash Free Payments",
  "cash-free-payments-text":
    "Benefit from cash-free transaction with the customers through secured payment gate",
  "easy-to-use-control-panel": "Easy to Use Control Panel",
  "easy-to-use-control-panel-text":
    "Manage your business in FindHeli web control panel. Register your fleet aircrafts, heliports and availabilities.",
  "crew-and-fleet-management": "Crew & Fleet Management",
  "crew-and-fleet-management-text-1":
    "Manage your fleet load and respective availabilities using Fleet Tab Control Panel",
  "crew-and-fleet-management-text-2":
    "Manage roles, assign crew and pilots to perform flights booked by FindHeli customers.",
  "special-offers-extra-revenues": "Special Offers, Extra Revenues",
  "special-offers-extra-revenues-text-1":
    "Promote special offers and exclusive flight options for FindHeli users.",
  "special-offers-extra-revenues-text-2":
    "Earn Extra Revenues by Advertising Carry Flights in our Progressive Web App",
  "contact-us": "Contact us",
  "contact-us-text":
    "Having any trouble with your booked flights? Couldn't find answers to your questions in our FAQ?",
  "get-in-touch-with-our-support-team": "Get in touch with our support team",
  "send-us-a-message": "Send us a message",
  "first-name": "First Name",
  "last-name": "Last Name",
  phone: "Phone",
  "how-can-we-help-you": "How can we help you?",
  send: "Send"
};
