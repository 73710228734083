export default {
  "link-about-us": "Sobre nós",
  "link-travel": "Viaje",
  "link-partner": "Se torne um parceiro",
  "link-contact": "Contato",
  "link-login": "Entrar",
  "link-faq": "Perguntas frequentes",
  "follow-us": "Siga nos",
  "home-page-title": "Viajar de helicóptero nunca foi tão fácil",
  "home-page-subtitle-1": "Marque um vôo",
  "home-page-subtitle-2": "Se torne um parceiro",
  "home-text-user":
    "Solicite o helicóptero que melhor te atende. Em qualquer lugar a qualquer hora.",
  "home-text-partner":
    "Cadastre helicópteros e transporte clientes FindHeli de todo o globo.",
  "view-more": "Ver mais",
  "search-helicopters": "Procure Helicópteros",
  "register-as-provider": "Cadastre-se como fornecedor",
  "about-us-page-title":
    "Bem-vindo a FindHeli - Uma Plataforma Global Online para Reservar Vôos de Helicóptero.",
  "about-us-mid-title": "O que é FindHeli?",
  "about-us-mid-subtitle-1": "Uma solução global",
  "about-us-mid-text-1":
    "FindHeli conecta clientes de todo o mundo com os operadores de helicópteros mais relevantes em apenas alguns cliques.",
  "about-us-subtitle-2": "Progressive Web App",
  "about-us-mid-text-2":
    "Não é necessário fazer download. Nosso app online pode ser usado em desktops e smartphones.",
  "about-us-mid-subtitle-3": "Plataforma para Reservas",
  "about-us-mid-text-3":
    "Parceiros FindHeli recebem solicitações online e tranportam passageiros de todo o mundo.",
  "who-is-it-for": "Para quem o FindHeli foi feito?",
  "who-is-it-for-text":
    "O app FindHeli foi criado para usuários que viajam com frequência e desejam um modo mais eficiente e agradável de viajar com helicópteros.",
  "how-does-it-work": "Como funciona?",
  "how-does-it-work-text":
    "O app FindHeli foi criado para usuários que viajam com frequência e desejam um modo mais eficiente e agradável de viajar com helicópteros.",
  "where-does-it-work": "Onde o Findheli opera?",
  "where-does-it-work-text":
    "A plataforma global online FindHeli atualmente opera nos seguintes países:",
  usa: "Estados Unidos",
  "united-kingdom": "Reino Unido",
  europe: "Europa",
  switzerland: "Suíça",
  russia: "Rússia",
  "become-a-findheli-partner": "Se torne um parceiro do Findheli",
  "partner-account-initiation-process":
    "Processo de iniciação da conta do parceiro",
  "request-an-account": "Solicite uma conta",
  "request-an-account-text-1":
    "Visite www.findheli.com/partner para solicitar o registro da sua conta",
  "request-an-account-text-2": "Revise e aceite os Termos e Condições",
  "sign-into-admin-panel": "Entre no painel de administração",
  "sign-into-admin-panel-text-1":
    "Espere enquanto FindHeli confere seus dados e valida sua conta.",
  "sign-into-admin-panel-text-2": "Siga as intruções enviadas pelo e-mail.",
  "start-using-findheli": "Comece a operar via FindHeli",
  "start-using-findheli-text":
    "Cadastre heliportos, frota e ofertas especiais.",
  "become-a-partner": "Se torne um parceiro",
  "submit-a-request": "Envie uma Solicitação",
  "first-and-last-name": "Nome e Sobrenome",
  "company-name": "Nome da Empresa",
  country: "País",
  website: "Website",
  email: "E-mail",
  "your-message": "Sua mensagem",
  "send-partnership-request": "Enviar Solicitação de Parceria",
  "frequently-asked-questions": "Perguntas Frequentes",
  "about-findheli-booking-platform": "Sobre a Plataforma de Reservas FindHeli",
  "what-is-findheli-and-who-is-it-for":
    "O que é e para quem foi feito FindHeli?",
  "what-is-findheli-and-who-is-it-for-text":
    "FindHeli é uma plataforma global online para reserva de vôos de helicóptero. Torna o processo de reserva em algo simples, rápido e fácil. Nosso App online foi criado para usuários que viajam frequentemente com vôos de helicóptero.",
  "how-does-it-work-text-2":
    "Ao usar o app FindHeli, selecione um local para decolar e um para pousar, dia,horário do vôo e número de passageiros. O app FindHeli mostrará helicópteros relevantes, basta escolher um e enviar uma solicitação para o operador.",
  "where-it-currently-operates": "Onde FindHeli opera atualmente?",
  "where-it-currently-operates-text":
    "FindHeli começou na Inglaterra e já opera globalmente em diversas localidades como Estados Unidos, França, Suíca, Itália e Rússia.",
  "how-the-flight-price-is-calculated": "Como é calculado o preço dos vôos?",
  "how-the-flight-price-is-calculated-text":
    "O cálculo é simples. Cada helicóptero tem um valor por quilômetro que é multiplicado pela distância e somado as variáveis referentes ao local de destino como trânsito em aeroporto, taxas para decolagem e pouso, cobrança por peso e tipo de helicóptero e impostos.",
  "how-long-it-takes-to-confirm-the-booking":
    "Quanto tempo demora para mu vôo ser confirmado?",
  "how-long-it-takes-to-confirm-the-booking-text":
    "FindHeli envie os dados da sua solicitação ao operador imediatamente. Pode demorar até 24h para confirmar a disponibilidade do helicóptero. Após a confirmação, FindHeli envia a você uma resposta no App e um e-mail. Para aceitar e reservar o vôo é preciso efetuar o pagamento com seu cartão de crédito.",
  "what-else-do-i-need-to-know": "É necessário levar algo para o vôo?",
  "what-else-do-i-need-to-know-text":
    "Assim como em vôos padrão, você terá de apresentar um documento com foto para provar sua identidade antes do vôo. É necessário chegar no heliporto/aeroporto 20 a 30 minutos antes do vôo para iniciar o procedimento de embarque. Todos os operadores FindHeli são certificados e licensiados.",
  "find-a-helicopter-anytime-and-anywhere":
    "Encontre um Helicóptero em qualquer lugar e em qualquer hora",
  "open-findheli-app": "Abrir Findheli app",
  "how-does-findheli-work": "Como o Findheli funciona?",
  "input-your-trip-details": "Coloque os detalhes da sua viagem",
  "input-your-trip-details-text":
    "Escolha local de origem, destino, dia e horário do seu vôo para selecionar o melhor helicóptero para você.",
  "select-an-aircraft": "Selecione um Helicóptero",
  "select-an-aircraft-text":
    "Selecione um de muitos helicópteros por perto e solicite uma reserva.",
  "review-flight-proposal": "Revise a Proposta de Vôo",
  "review-flight-proposal-text":
    "Revise e aceite os detalhes do vôo na proposta enviada pelo App FindHeli.",
  "confirm-and-book-flight": "Confirme e reserve o vôo.",
  "confirm-and-book-flight-text":
    "Depois de aceitar a proposta, confirme a reserva do vôo com uma pré autorização do cartão e crédito.",
  "what-findheli-offers": "O que FindHeli oferece?",
  "destination-flights": "Destination Flights",
  "destination-flights-text":
    "Escoha seu local e partida e destino para encontrar o melhor helicóptero disponívels para sua viagem.",
  "scenic-flights": "Vôos Turísticos",
  "scenic-flights-text":
    "Ofertas especiais para vistas incríveis de cidades e paisagens disponíveis no mundo todo.",
  "heli-ski-drop-offs": "Descida de Helicóptero para Ski",
  "heli-ski-drop-offs-text":
    "Precisa de um helicóptero para subir as montanhas? Reserve facilmente através da plataforma FindHeli.",
  "why-to-use-findheli": "Por quê usar FindHeli?",
  "global-and-international": "Global e Internacional",
  "global-and-international-text":
    "Disponível em múltiplas línguas em várias localidades no mundo.",
  "instant-price-estimate": "Estimativa de preço instantânea.",
  "instant-price-estimate-text":
    "Escolha local de origem, destino, dia e horário do seu vôo.",
  "quick-confirmation": "Confirmação Rápida",
  "quick-confirmation-text":
    "Nós encorajamos nossos operadores a confirmarem a disponiblidade do helicóptero em 24 horas.",
  "secured-data-protection": "Segurança dos seus dados garantida",
  "secured-data-protection-text":
    "Nossa base de dados é protegida para que as informações dos usuários não sejam roubadas.",
  "an-elevated-way-of-travel": "Uma maneira elevada de viajar",
  "findHeli-is-easy-to-use": "FindHeli é fácil de usar",
  "search-for-helicopters-text":
    "Procure por helicópteros e reserve um vôo diretamente na Plataforma Online FindHeli",
  "extend-your-operations-with-findheli": "Extenda suas operações com FindHeli",
  "why-register-in-findheli-booking-platform":
    "Por quê se cadastrar na Plataforma de Reservas FindHeli?",
  "global-network": "Uma rede global",
  "global-network-text":
    "Acesse a rede internacional de usuários FindHeli premium",
  "efficient-fleet-load": "Uso eficiente de frota",
  "efficient-fleet-load-text":
    "Ganhos extras com assentos disponíveis em vôos de ida ou volta.",
  "cost-saving-marketing": "Marketing integrado.",
  "cost-saving-marketing-text":
    "Reduzir os custos com publicidade em campanhas direcionadas utilizando a plataforma FindHeli.",
  "cash-free-payments": "Pagamentos digitais.",
  "cash-free-payments-text":
    "Beneficie-se de pagamentos digitais em um gateway seguro de pagamento.",
  "easy-to-use-control-panel": "Painel de Controle fácil de utilizar.",
  "easy-to-use-control-panel-text":
    "Gerencie seu negócio no painel de controle FindHeli. Cadastre suas aeronaves na frota, heliportos e disponibilidades.",
  "crew-and-fleet-management": "Gestão de Frota e Equipes.",
  "crew-and-fleet-management-text-1":
    "Gerencie sua frota e disponibilidades usando a aba de controle de frota no Painel de Controle",
  "crew-and-fleet-management-text-2":
    "Gerencie papéis, associe tripulação e pilotos para realizar vôos reservados por clientes FindHeli.",
  "special-offers-extra-revenues": "Ofertas Especiais, Ganhos extras",
  "special-offers-extra-revenues-text-1":
    "Promova ofertas especiais e vioos exclusivos para clientes FindHeli.",
  "special-offers-extra-revenues-text-2":
    "Receba ganhos extras ao disponibilizar vôos de linha em nosso App.",
  "contact-us": "Contate-nos",
  "contact-us-text":
    "Vous avez des problèmes avec vos vols réservés? Impossible de trouver des réponses à vos questions dans notre FAQ?",
  "contact-us-text":
    "Está tendo algum problema com seus voos reservados? Não foi possível encontrar respostas para suas perguntas em nosso FAQ?",
  "get-in-touch-with-our-support-team":
    "Entre em contato com nossa equipe de suporte",
  "send-us-a-message": "Envie-nos uma mensagem",
  "first-name": "Nome",
  "last-name": "Sobrenome",
  phone: "Telefone",
  "how-can-we-help-you": "Como podemos ajudar?",
  send: "Enviar"
};
