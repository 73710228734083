import "./styles/styles.css";
import "./javascripts/components/collapse";
import "./javascripts/components/form";
import "./javascripts/components/mobile-nav-toggle";
import "./javascripts/components/nav-active";
import "./javascripts/components/slider";
import Translator from "./javascripts/components/translator";
import Contact from "./javascripts/components/contact";

document.addEventListener("DOMContentLoaded", () => {
  const translator = new Translator({
    triggerSelector: ".dropdown-trigger",
    dropdownSelector: ".dropdown-menu",
    containerSelector: ".dropdown",
    dropdownOption: ".dropdown-menu-button"
  });

  const contact = new Contact(".contact-submit");
});
