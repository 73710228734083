import { tns } from "tiny-slider/src/tiny-slider"

const slider = tns({
    container: '#travel-offers',
    items: 1,
    slideBy: 'page',
    controls: false,
    nav: true,
    navContainer: '#tns-dots',
    gutter: 24,
    responsive: {
        768: {
            items: 2
        },
        1024: {
          items: 3
        }
      }
});
