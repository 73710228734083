import $ from "jquery";

class Toast {
  constructor() {
    this.$toast = $(".toast");
    this.timeoutDuration = 3000;
    this.handleClose = this.handleClose.bind(this);

    this.addEventListener();
  }

  addEventListener() {
    this.$toast.on("click", this.handleClose);
  }

  setMessage(message) {
    this.$toast.text(message);
  }

  show() {
    this.$toast.addClass("active");
    this.scheduleClose();
  }

  handleClose() {
    this.$toast.removeClass("active");
  }

  scheduleClose() {
    this.timeout = null;
    this.timeout = setTimeout(this.handleClose, this.timeoutDuration);
  }
}

export default Toast;
